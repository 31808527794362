/* Typography */
h1 {
    font-weight: normal;
    margin: 0;
    font-size: 24px;
}

h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
}

.card {
    background-color: #ffffff;
    padding: 1em;
    margin-bottom: 16px;
    @include border-radius($borderRadius);

    &.card-w-title {
        padding-bottom: 2em;
    }

    h1 {
        margin: 1em 0 .5em 0;
        border-bottom: 1px solid #d5d5d5;
        padding: .1em;
        font-size: 24px;

        &:first-child {
            margin: 0 0 .5em 0;
        }
    }
}
.active-tab {
    background-color: #f7f7f7;
    border-left: 0.2em solid rgb(92, 74, 181);
    padding: 1em;
    margin-bottom: 16px;
    @include border-radius($borderRadius);

    &.card-w-title {
        padding-bottom: 2em;
    }

    h1 {
        margin: 1em 0 .5em 0;
        border-bottom: 1px solid #d5d5d5;
        padding: .1em;
        font-size: 24px;

        &:first-child {
            margin: 0 0 .5em 0;
        }
    }
}

.p-g {
    -ms-flex-wrap: wrap;
}

.tooltipMenu {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black;
  }
  
  .tooltipMenu .tooltiptext {
    visibility: hidden;
    width: 120px;
    //background-color: #555;
    color: #5c4ab5;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltipMenu .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #5c4ab5 transparent transparent transparent;
  }
  
  .tooltipMenu:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltipClipBoard {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black;
  }

  .tooltipClipBoard .tooltiptext {
    visibility: hidden;
    width: 40px;//120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    //left: 50%;
    //margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

	font-size: 10px;
  }
	.tooltipClipBoard .tooltiptext {
    visibility: hidden;
    //width: 40px;//120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    //left: 50%;
    //margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

    font-size: 10px;
  }
	.tooltipClipBoard .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #5c4ab5 transparent transparent transparent;
  }

  .tooltipClipBoard:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }




  .tooltipMenu .tooltiptext1 {
    visibility: hidden;
    width: 120px;
    //background-color: #555;
    color: #5c4ab5;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltipMenu .tooltiptext1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #5c4ab5 transparent transparent transparent;
  }
  .tooltipMenu:hover .tooltiptext1 {
    visibility: visible;
    opacity: 1;
  }
  .tooltipClipBoard .tooltiptext1 {
    visibility: hidden;
    width: 40px;//120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    //left: 50%;
    //margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

    font-size: 10px;
  }
.tooltipClipBoard .tooltiptext1 {
    visibility: hidden;
    width: 100%;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    //left: 50%;
    //margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

    font-size: 10px;
  }
.tooltipClipBoard .tooltiptext1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #5c4ab5 transparent transparent transparent;
  }


  .tooltipClipBoard:hover .tooltiptext1 {
    visibility: visible;
    opacity: 1;
  }

