
	.profile
	{
		margin-top:6px;
	}
	.profileTop
	{
		margin-top:10px;
	}
	.p-dialog-header {
		padding-bottom: 0;	
	}










