	@mixin placeholder {
  		::-webkit-input-placeholder {@content}
  		:-moz-placeholder           {@content}
  		::-moz-placeholder          {@content}
  		:-ms-input-placeholder      {@content}
	}

	@include placeholder {
		font-size: 12px;
    	color: #999;//rgb(92, 74, 181);
    	font-weight:500;
	}
.pi .pi-fw .pi-home {
position: relative;
display: inline-block;
border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
	body .p-inputtext 
	{
    	font-size: 14px !important;//26 oct 2020
    	color: #999;//rgb(92, 74, 181);
    	font-weight:500;
    	background-color: rgb(240,240,240);
    	padding: 0.429em;
    	border: 0;//1px solid #a6a6a6;
    	transition: border-color 0.2s, box-shadow 0.2s;
    	-webkit-appearance: none;
    	-moz-appearance: none;
    	appearance: none;
    	border-radius: 10px;
		padding: 10px;
    	padding-left: 15px;
	}
	body .p-dropdown {
    	background: #f0f0f0;
    	border: 0;
		transition: border-color 0.2s, box-shadow 0.2s;
    	border-radius: 10px;
	}
	body .p-dropdown .p-dropdown-trigger {
    	background-color: #f0f0f0;
    	width: 2em;
    	line-height: 2em;
    	text-align: center;
    	padding: 0;
    	color: #848484;
    	border-radius: 10px;
	}
		.span-custom-success {
    		font-size: 9px;
    		color: rgb(92,74,181);
    		font-weight:600;
    		display: flex;
			align-items: center;
		}
		.span-custom-failed {
    		font-size: 9px;
    		color: orange;
    		font-weight:600;
    		display: flex;
			align-items: center;
		}
		.span-custom-processing {
            font-size: 9px;
            color: #9cc1fd;
            font-weight:600;
            display: flex;
            align-items: center;
        }
    
    .summary {
        position: relative;
        
        .title {
            font-size: 20px;
        }
        
        .detail {
            color: $textSecondaryColor;
            display: block;
            margin-top: 10px;
        }
        
        .count {
            color: #ffffff;
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 24px;
            padding: 7px 14px;
            @include border-radius($borderRadius);
            
            &.visitors {
                background-color: #20d077;
            }
            
            &.purchases {
                background-color: #f9c851;
            }
            
            &.revenue {
                background-color: #007be5;
            }
        }
    }
	.p-button {
		color: #ffffff;
		background: rgb(84, 67, 163);//#2196F3;
		border: 1px solid rgb(84, 67, 163);
		border-radius: 3px;
	}
	.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
		color: #ffffff;
		background: rgb(84, 67, 163);//#0288D1;
		border: 0;//1px solid #0288D1;
	}
    .highlight-box {
		box-shadow: 11px 4px 5px 1px rgba(219, 216, 216, 0.2);//ROHIT
		border-radius: 10px;//ROHIT
		//min-height: 10.5em;//ROHIT
        //height: 170px;

        @include clearfix();
        
        .initials {
            height: 100%;
            float: left;
            width: 50%;
            text-align: center;
            padding: 1em;
			height: 100%;

			border-bottom-left-radius: 10px;//ROHIT
			border-top-left-radius: 10px;//ROHIT


            > span {
                font-size: 48px;
            }
        }
        
        .highlight-details {
            height: 100%;
            background-color: #ffffff;
            float: left;
            width: 50%;
            padding: 1em;
			height: 100%;

   	 		width: 100%;//ROHIT
    		border-radius: 10px;//ROHIT


            i {
                font-size: 14px;//24px;//ROHIT
                vertical-align: middle;
                margin-right: .25em;

            }
            
            .count {
                color: $textSecondaryColor;
                font-size: 32px;
                display: block;

				color:rgb(92,74,181);//ROHIT
				text-align:center;//ROHIT
				font-weight:600;//ROHIT
            }
        }
    }
    
    .task-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        
        li {
            padding: .5em .25em;
            border-bottom: 1px solid $dividerColor;
            @include clearfix();
        }
        
        .p-checkbox {
            vertical-align: middle;
            margin-right: .5em;
        }
        
        .task-name {
            vertical-align: middle;
        }
        
        i {
            float: right;
            font-size: 24px;
            color: $textSecondaryColor;
        }

        .p-panel-content {
            min-height: 256px;
        }
    }

    .contact-form {
        .p-panel-content {
            min-height: 256px;
        }
    }

    .contacts {

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                border-bottom: 1px solid $dividerColor;
                
                button {
                    padding: 9px;
                    width: 100%;
                    box-sizing: border-box;
                    text-decoration: none;
                    position: relative;
                    display: block;
                    @include border-radius(2px);
                    @include transition(background-color .2s);
                    
                    .name {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        font-size: 18px;
                    }
                    
                    .email {
                        position: absolute;
                        right: 10px;
                        top: 30px;
                        font-size: 14px;
                        color: $textSecondaryColor;
                    }
                    
                    &:hover {
                        cursor: pointer;
                        background-color: #eeeeee;
                    }
                }
                
                &:last-child {
                    border: 0;
                }
            }
        }
 
        .p-panel-content {
            min-height: 256px;
        }
    }
        
    .activity-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        
        li {
            border-bottom: 1px solid $dividerColor;
            padding: 16px 8px;
            
            .count {
                font-size: 24px;
                color: #ffffff;
                background-color: #007be5;
                font-weight: 700;
                display: inline-block;
                padding: .25em .5em;
                @include border-radius($borderRadius);
            }
            
            &:first-child {
                border-top: 1px solid $dividerColor;
            }
            
            &:last-child {
                border: 0;
            }

            .p-g-6:first-child {
                font-size: 18px;
                padding-left: 0;
            }
            
            .p-g-6:last-child {
                text-align: right;
                color: $textSecondaryColor;
            }
        }
    }
	.product-badge.status-failed {
 	   background: #ffcdd2;
 	   color: #c63737;
	}
	.product-badge.status-success {
       background: #8cbe41;
       color: #fff;
    }
	.product-badge.status-pending {
       background: orange;
       color: #fff;
    }

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;
	}

/*FOR MOBILE DIALOG START*/
.p-dialog-content
{
	overflow-y: visible;
}
/*FOR MOBILE DIALOG END*/

::-webkit-scrollbar {//22 oct 2020 for sidebar scroll
    //display: none;
}

.p-dialog .p-dialog-header
{
	color:#5c4ab5;
}
.p-fieldset .p-fieldset-legend {
    color: #fff;
    background: rgb(2,136,209);
	border-radius: 10px;
	padding: 10px;
}
.custom-heading {
	padding-left: 1em;
    padding-right: 1em;
    border-radius: 10px;
    font-size: 20px;
	background-color: rgb(156,193,253);
}
.p-tag.p-tag-info {
    background-color: #9cc1fd;
    color: #ffffff;
}
.p-fieldset .p-fieldset-legend {
    color: #fff;
    background-color: #9cc1fd;
    border-radius: 10px;
    padding: 10px;
}
.layout-sidebar .menuitem-badge {
    display: inline-block;
    margin-left: 4px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    border-radius: 50%;
    background-color: rgb(253,149,25);
	float:right;
	font-weight: 700;
}
.p-dialog-content {
    //overflow-y: scroll;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: rgb(253,149,25);
    background: rgb(253,149,25);
}

.p-rnputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #689F38;
}
.edit_hover_class a{
	visibility:hidden;
}
.edit_hover_class:hover a{
	visibility:visible;
}

.show_copy:hover .edit_hover_class a{
 visibility:visible;
}

.ui-datatable-data-empty .ui-dt-c {
    width: 100% !important;
  }

  .multiselect-demo .p-multiselect {
    min-width: 15rem;
}

.multiselect-demo .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.multiselect-demo .multiselect-custom .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

.multiselect-demo .multiselect-custom .country-item-value img.flag {
    width: 17px;
}
