//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

body .p-panel .p-panel-titlebar {
    border: 0px solid #c8c8c8;
    background-color: #f7f7f7;
}

body .p-panel .p-panel-content {
    border: 0px solid #c8c8c8;
}

// .p-panel .p-panel-titlebar-icon{
//     width: 6%!important;
//     cursor: pointer;
//     //fa: "ui-icon ui-icon-star";
// }

.p-panel .p-panel-titlebar-icon .ui-icon-gear {
    background-position: -224px -112px;
}

.p-datatable,
   .p-datatable .p-datatable-tablewrapper table {
        font-size: 14px !important;
}

.p-inputtext {
    font-size: 14px !important;
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}
