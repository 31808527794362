.layout-menu-container {
    //padding-bottom: 120px;//22 oct 2020 for sidebar scroll
}

.layout-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
	padding: 1em;
    margin-bottom: 16px;
    border-radius: 3px;
    margin-left: '';//3em;
    margin-right: '';//3em;
    //box-shadow: 11px 4px 5px 1px rgba(219, 216, 216, 0.2);
    //background-color: #fff;
    //border-left: 1px solid rgb(252, 252, 249);
    li {
        a {
			padding: 1em;//
    		text-align:center;//	
            cursor: pointer;
            position: relative;
            text-decoration: none;
            display: block;
            @include transition(color $transitionDuration);

            i {
                font-size: 30px;
                vertical-align: middle;
				color: rgb(92,74,181);//ROHIT
            }

            span {
                margin-left: .25em;
                vertical-align: middle;
                line-height: 18px;
                display: inline-block;
            }

            .menuitem-toggle-icon {
                position: absolute;
                top: 50%;
                right: 1em;
                margin-top: -9px;
            }

            &.router-link-active {
                font-weight: 700;
            }
        }

        &.active-menuitem {
            > a {
                .menuitem-toggle-icon {
                    @include icon-override("\e933");
                }
            }

            > ul {
                max-height: 500px;
            }
        }
    }

    > li {
        > a {
            padding: 1em;
			padding-top:2.2em;
			padding-bottom:2.2em;

            span {
                font-size: $fontSize;
            }
        }

        &:last-child {
            > a {
                border-bottom: 1px solid 0;//$menuitemBorderColor;//ROHIT
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            padding-left: 1.5em;
            max-height: 0;
            overflow: hidden;
            @include transition-property(max-height);
            @include transition-duration(0.4s);
            @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

            li {
                a {
                    cursor: pointer;
                    padding: .75em 1em;
                    border-top: 0 none;

                    span {
                        font-size: $submenuFontSize;
                    }
                }

                &:last-child {
                    padding-bottom: 1em;
                }
            }
        }
    }
}

.layout-sidebar-light {
    @include linear-gradient($menuBgColorFirst, $menuBgColorLast);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    background-color: #f7f7f7;

    .layout-menu {
        > li {
            > a {
                border-top: 1px solid 0;//rgb(92, 74, 181);//$menuitemBorderColor;//ROHIT
            }

            &:last-child {
                > a {
                    border-bottom: 1px solid 0;//rgb(92, 74, 181);//$menuitemBorderColor;//ROHIT
                }
            }

            ul {
                background-color: $menuitemActiveBgColor;
            }
        }

        li {
            a {
                color: $menuitemColor;

                &.router-link-active {
                    color: $menuitemActiveRouteColor;
                }

                &:hover {
                    color: $menuitemHoverColor;
                }
            }

            &.active-menuitem {
                > a {
                    background-color: $menuitemActiveBgColor;
                    color: $menuitemActiveColor;
                }
            }
        }
    }
}

.layout-sidebar-dark {
    @include linear-gradient($menuDarkBgColorFirst, $menuDarkBgColorLast);

    .layout-menu {
        > li {
            > a {
                border-top: 1px solid $menuitemDarkBorderColor;
            }

            &:last-child {
                > a {
                    border-bottom: 1px solid $menuitemDarkBorderColor;
                }
            }

            ul {
                background-color: $menuitemDarkActiveBgColor;
            }
        }

        li {
            a {
                color: $menuitemDarkColor;

                &.router-link-active {
                    color: $menuitemActiveRouteColor;
                }

                &:hover {
                    color: $menuitemDarkHoverColor;
                }
            }

            &.active-menuitem {
                > a {
                    background-color: $menuitemDarkActiveBgColor;
                    color: $menuitemDarkActiveColor;
                }
            }
        }
    }
}
